import 'owl.carousel';

window.jQuery($ =>
{
    (function (owlProto)
    {
        let viewport = owlProto.viewport;

        owlProto.viewport = function ()
        {
            if (this.options.responsiveBaseElement === 'parent')
            {
                return this.$element.parent().width();
            }
            return viewport.apply(this, arguments);
        }
    })($.fn.owlCarousel.Constructor.prototype);


    /*let $nba_references = */
    $('.nba-references')
        .addClass('owl-carousel owl-theme')
        .each(function ()
        {
            // let numNodes = this.children.length;

            $(this).owlCarousel({
                margin: 30,
                items: 1,
                nav: true,
                dots: false,
                loop: false,
                mouseDrag: false,
                touchDrag: false,
                pullDrag: false,
                // responsiveBaseElement: this.parentNode,
                // responsive: {
                //     margin: 30,
                //     1700: {
                //         items: Math.min(2, numNodes),
                //         slideBy: Math.min(2, numNodes),
                //         loop: false
                //     }
                // }
            })
        })

    ;

    const r = {};
    for (let i = 1; i < 8; i++)
    {
        r[i * 275 + 30 * (i - 1)] = {
            items: i,
            slideBy: i
        };
    }

    /*let $article_products = */
    $('.service-products')
        .addClass('owl-carousel owl-theme')
        .owlCarousel({
            margin: 30,
            items: 1,

            nav: true,
            dots: true,

            responsiveBaseElement: 'parent',
            responsive: r
        })
    ;


    let autoplayTimeout = 7500;
    /*let $nba_slides = */
    $(".nba_slides")
        .addClass('owl-carousel owl-theme')
        .owlCarousel({
            items: 1,
            // slideBy: 1,
            margin: 30,

            lazyLoad: true,
            lazyLoadEager: 1,

            // autoHeight: true,
            nav: true,
            dots: false,

            autoplay: true,
            autoplayTimeout: autoplayTimeout,
            autoplayHoverPause: true,

            loop: true,

            onInitialized: ({target}) =>
            {
                const $target = $(target);
                const animationStyle = `-webkit-animation-duration:${autoplayTimeout}ms;animation-duration:${autoplayTimeout}ms`
                const $progressBar = $(
                    `<div class="slider-progress-bar"><div class="progress" style="${animationStyle}"></div></div>`
                )
                $target.append($progressBar);
            },
            onChanged: ({type, target}) =>
            {
                if (type === 'changed')
                {
                    const $progressBar = $(target).find('.slider-progress-bar')
                    const clonedProgressBar = $progressBar.clone(true)

                    $progressBar.remove()
                    $(target).append(clonedProgressBar)
                }
            }

        })
    ;
});